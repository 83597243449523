import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@mui/material';
import DataTable from '../../Table';
import { ViewIcon } from '../../../shared/component/svgIcon';
import ProgressBar from '../../../shared/component/Progressbar';
import schedule from '../../../utils/api/schedule';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { changeDateFormat, changeTimeFormat } from '../../../utils/helpers';

function ScheduleStatus(props) {
  const dispatch = useDispatch();
  const [value, setValue] = useState();
  const columns = [
    {
      header: 'Device Name',
      accessorKey: 'device_name',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: 'Device Status',
      accessorKey: 'device_status',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ row }) => (
        <div>
          {
            row?.original?.device_status ? <span>Online</span> : <span className="device-received-status">Offline</span>
          }
        </div>
      ),
    },
    {
      header: 'Device Sync Status',
      accessorKey: 'device_status',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      size: 250,
      Cell: ({ row }) => (
        <div>
          {
            row?.original?.device_status
              ? <span>Received</span> : <span className="device-received-status">Not Received</span>
          }
        </div>
      ),
      Header: () => (
        <div className="schedule-status">
          <span>Device Sync Status</span>
          <Tooltip
            placement="top"
            title="Indicates if schedule for the device is received or not"
          >
            <div className="tooltip-container">
              <ViewIcon className="status-view-icon" />
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      header: 'Content Download Status',
      accessorKey: 'device_received',
      size: 250,
      Cell: ({ row }) => (
        <div className="content-tooltip">
          <Tooltip
            classes={{
              tooltip: 'custom-tooltips',
              arrow: 'custom-tooltip-arrow',
            }}
            title={(
              <div className="custom-content-title">
                <ul className="content-status-view">
                  {row?.original?.content_details.map((device) => (
                    <li
                      className="content-list"
                    >
                      <span
                        className="content-details-list"
                      >
                        <span className="content-list-name">{device?.contentName}</span>
                        <span className="content-list-time">
                          {device?.updatedAt ? changeTimeFormat(device?.updatedAt) : '-'}
                        </span>
                      </span>

                      {device?.status ? (
                        <span className="content-details-status">
                          ✔
                        </span>
                      ) : (
                        <span className="content-details-close">
                          ❌
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            placement="left"
          >
            <div>
              <ProgressBar
                current={row?.original?.downloaded_content}
                total={row?.original?.total_content}
                color="#30A84B"
              />
            </div>
          </Tooltip>
        </div>
      ),
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      filterVariant: 'none',
      Header: () => (
        <div className="schedule-status">
          <span>Content Download Status</span>
          <Tooltip
            placement="top"
            title="Indicates if contents for the device is downloaded or not"
          >
            <div className="tooltip-container">
              <ViewIcon className="status-view-icon" />
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      header: 'Last Received Time',
      accessorKey: 'device_updatedAt',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      size: 250,
      Header: () => (
        <div className="schedule-status">
          <span>Last Received Time</span>
          <Tooltip
            placement="top-end"
            title="Time when the device last successfully received its schedule update"
          >
            <div className="tooltip-container">
              <ViewIcon className="status-view-icon" />
            </div>
          </Tooltip>
        </div>
      ),
      Cell: ({ row }) => (
        <div>
          {row?.original?.device_updatedAt
            ? (
              <span>
                {changeDateFormat(row?.original?.device_updatedAt)} {changeTimeFormat(row?.original?.device_updatedAt)}
              </span>
            ) : <span>-</span>}
        </div>
      ),
    },
  ];
  useEffect(() => {
    schedule.getScheduleStatus({ scheduleId: props?.scheduleId })
      .then((response) => {
        setValue(response?.data);
      })
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
  }, []);
  return (
    <div className="schedule-status-details">
      <div className="device-count-details">
        <span>Total Device : {value?.totalDeviceCount || 0}</span>
        <span>Total Content : {value?.totalContentCount || 0}</span>
      </div>
      <DataTable
        header={columns}
        value={value?.result || []}
        onFilterOrSortingChange={() => { }}
        reduxColumnFiltersSorting={() => { }}
        totalPageCount={1}
        pageCount={1}
        scrollData={() => { }}
        disableFullScreen
        totalDataCount={4}
        disableFullScreenToggle
        rowSelection={{}}
        setRowSelection={() => { }}
        columnVisibility={{}}
        setColumnVisibility={() => { }}
      />
    </div>
  );
}
ScheduleStatus.propTypes = {
  scheduleId: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      content_details: PropTypes.arrayOf,
      device_status: PropTypes.bool,
      device_received: PropTypes.bool,
      start_date_utc: PropTypes.string,
      total_content: PropTypes.number,
      downloaded_content: PropTypes.number,
      device_updatedAt: PropTypes.string,
    }),
  }),
};
ScheduleStatus.defaultProps = {
  row: {},
  scheduleId: '',
};
export default ScheduleStatus;
