import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cookies from 'js-cookie';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { CircularProgress } from '@mui/material';
import eyeIcon from '../../../assets/icons/eye.png';
import eyeSlashIcon from '../../../assets/icons/eye-slash.png';
// import DotLoader from '../Loader';

function Input(props) {
  const [type, setType] = useState(props?.type);
  const [image, setImage] = useState(eyeIcon);
  const changes = (e) => {
    props?.change(props?.name, e.target.value);
  };

  const changeType = () => {
    setType(type === 'password' ? 'text' : 'password');
    setImage(type === 'password' ? eyeSlashIcon : eyeIcon);
  };

  return (
    <div className="input-black">
      <label className="label" htmlFor={props?.name}>
        {props?.label}{props?.required && <span className="text-danger">*</span>}
      </label>
      <input
        type={props?.type === 'password' ? type : props?.type}
        name={props?.name}
        className={props?.error ? 'input-box input-box-error' : 'input-box'}
        value={props?.value}
        onChange={changes}
        readOnly={props?.readonly}
        placeholder={props?.placeholder}
        id={props?.name}
        maxLength={props?.maxLength}
        autoComplete="off"
      />
      {props?.type === 'password'
        && (
          <span
            role="presentation"
            className="password-view-icon"
            onClick={changeType}
          >
            <img src={image} alt="eye" className="image" />
          </span>
        )}
      {props?.error && <p className="error-message">{props?.errorMessage}</p>}
    </div>
  );
}

function Input1(props) {
  const [type, setType] = useState(props?.type);
  const currentLanguageCode = cookies.get('i18next');
  const [image, setImage] = useState(eyeIcon);
  const changes = (e) => {
    props?.change(e.target.name, e.target.value);
  };

  const changeType = () => {
    setType(type === 'password' ? 'text' : 'password');
    setImage(type === 'password' ? eyeSlashIcon : eyeIcon);
  };

  return (
    <div className="input">
      <label className="label" htmlFor={props?.name}>
        {props?.icon && <img className="image" src={props?.icon} alt="icon" />}
        {props?.label}{props?.required && <span className="text-danger">*</span>}
      </label>
      <input
        type={type}
        name={props?.name}
        className={props?.error ? 'input-box input-box-error' : 'input-box'}
        value={props?.value}
        onChange={changes}
        id={props?.name}
        placeholder={props?.placeholder}
        autoComplete="off"
        readOnly={props?.readonly}
      />
      {props?.type === 'password'
        && (
          <span
            role="presentation"
            className={currentLanguageCode === 'ar' ? 'ar' : 'password-view-icon'}
            onClick={changeType}
          >
            <img src={image} alt="eye" className="image" />
          </span>
        )}
      {props?.error && <p className="error-message">{props?.errorMessage}</p>}
    </div>
  );
}

function InputInnerIcon(props) {
  const changes = (e) => {
    props?.change(e.target.name, e.target.value, e.keyCode);
  };
  return (
    <div className="input-inner-icon">
      {props?.icon && <img className="image" src={props?.icon} alt="icon" />}
      <input
        type={props?.type}
        name={props?.name}
        className="input-box"
        placeholder={props?.label}
        value={props?.value}
        required={props?.required}
        onKeyUp={changes}
        onChange={changes}
        autoComplete="off"
      />
    </div>
  );
}

function InputRightInnerIcon(props) {
  const changes = (e) => {
    props?.change(e.target.name, e.target.value);
  };
  const click = () => {
    props?.click();
  };
  return (
    <div className="input">
      <label className="label" htmlFor={props?.name}>
        {props?.label}{props?.required && <span className="text-danger">*</span>}
      </label>
      <div className={props?.error ? 'input-inner-icon input-inner-icon-error' : 'input-inner-icon'}>
        <input
          type={props?.type}
          name={props?.name}
          className="input-box"
          value={props?.value}
          onChange={changes}
          id={props?.name}
          readOnly={props?.readonly}
          placeholder={props?.placeholder}
          autoComplete="off"
        />
        {props?.icon && (
          <div onClick={click} role="presentation">
            <img className="image" src={props?.icon} alt="icon" />
          </div>
        )}
      </div>
      {props?.error && <p className="error-message">{props?.errorMessage}</p>}
    </div>
  );
}

function InputIncreaseDecrease(props) {
  const changes = (e) => {
    props?.change(e.target.name, Number(e.target.value), props?.index);
  };

  const valueChange = (type) => {
    if (type === 'add') {
      props?.change(props?.name, Number(props?.value) + 1, props?.index);
    } else if (type === 'minus' && props?.value > props?.min) {
      props?.change(props?.name, Number(props?.value) - 1, props?.index);
    }
  };

  return (
    <div className="input-black input-increase-decrease">
      <label className="label" htmlFor={props?.name}>
        {props?.label}{props?.required && <span className="text-danger">*</span>}
      </label>
      {props?.symbol && (
        <p
          className="input-symbol"
          style={{
            top: props?.top ? props?.top : '',
            left: props?.left ? props?.left : '',
            right: props?.right ? props?.right : '',
            color: props?.value ? '#1F1F1F' : '#8E8E8E',
          }}
        >
          {props?.symbol}
        </p>
      )}
      <div className="input-wrap">
        <div
          className="input-operator"
          role="presentation"
          onClick={() => valueChange('minus')}
        >
          -
        </div>
        <input
          type={props?.type}
          name={props?.name}
          className="input-box input-box-symbol"
          value={props?.value}
          onChange={changes}
          readOnly={props?.readonly}
          id={props?.name}
          autoComplete="false"
          placeholder={props?.placeholder}
          min={props?.min}
          max={props?.max}
          onWheel={(e) => e.target.blur()}
        />
        <div
          className="input-operator"
          role="presentation"
          onClick={() => valueChange('add')}
        >
          +
        </div>
      </div>
    </div>
  );
}

function TextArea(props) {
  const changes = (e) => {
    props?.change(e.target.name, e.target.value);
  };

  return (
    <div className="input-black">
      <label className="label" htmlFor={props?.name}>
        {props?.label}{props?.required && <span className="text-danger">*</span>}
      </label>
      <textarea
        name={props?.name}
        className={props?.error ? 'input-box input-box-error' : 'input-box'}
        value={props?.value}
        onChange={changes}
        id={props?.name}
        placeholder={props?.placeholder}
        readOnly={props?.readonly}
        autoComplete="off"
      />
      {props?.error && <p className="error-message">{props?.errorMessage}</p>}
    </div>
  );
}

function InputDate(props) {
  const handleDateChange = (data) => {
    // if (!validation.validationError) {
    props?.change(props?.name, data);
    // }
  };

  return (
    <div className="input-black">
      <label className="label" htmlFor={props?.name}>
        {props?.label}{props?.required && <span className="text-danger">*</span>}
      </label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={['DatePicker']}
          sx={{
            paddingTop: 0,
          }}
        >
          <DatePicker
            name={props?.name}
            value={props?.value}
            onChange={handleDateChange}
            disablePast={props?.disablePast}
            disableFuture={props?.disableFuture}
            disabled={props?.disabled}
            minDate={props?.minDate}
            readOnly={props?.readOnly}
            sx={{
              width: '100%',
              minWidth: '0px !important',
              '.MuiStack-root': {
                paddingTop: '0px !important',
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: props?.disabled ? '0.75px solid #c4c4c4 !important' : '0.75px solid #CED0D3 !important',
              },
              '.MuiOutlinedInput-input': {
                padding: props?.customPadding ? props?.customPadding : '11.75px 9px !important',
                fontSize: '14px !important',
              },
              '.MuiIconButton-root': {
                padding: '6px 8px !important', // Adjust the value as needed
              },
            }}
          />
        </DemoContainer>
        {props?.error && <div className="error-message">{props?.errorMessage}</div>}
      </LocalizationProvider>
    </div>
  );
}

function InputTime(props) {
  const handleDateChange = (data) => {
    // if (!validation.validationError) {
    props?.change(props?.name, data);
    // }
  };

  return (
    <div className="input-black">
      <label className="label" htmlFor={props?.name}>
        {props?.label}{props?.required && <span className="text-danger">*</span>}
      </label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={['DigitalClock', 'MultiSectionDigitalClock']}
          sx={{
            paddingTop: 0,
          }}
        >
          <TimePicker
            timeSteps={{ hours: 1, minutes: 1 }}
            views={['hours', 'minutes']}
            name={props?.name}
            value={props?.value || null}
            onChange={handleDateChange}
            disablePast={props?.disablePast}
            disabled={props?.disabled}
            minTime={props?.minTime}
            sx={{
              width: '100%',
              minWidth: '0px !important',
              '.MuiOutlinedInput-notchedOutline': {
                border: props?.disabled ? '0.75px solid #c4c4c4 !important' : '0.75px solid #CED0D3 !important',
              },
              '.MuiOutlinedInput-input': {
                padding: '11.75px 9px !important',
                fontSize: '14px !important',
              },
            }}
          />
        </DemoContainer>
        {props?.error && <div className="error-message">{props?.errorMessage}</div>}
      </LocalizationProvider>
    </div>
  );
}

function InputInnerSymbol(props) {
  const changes = (e) => {
    props?.change(e.target.name, e.target.value, props?.index);
  };

  return (
    <div className="input-black input-inner-symbol">
      <label className="label" htmlFor={props?.name}>
        {props?.label}{props?.required && <span className="text-danger">*</span>}
      </label>
      {props?.symbol && (
        <p
          className="input-symbol"
          style={{
            top: props?.top ? props?.top : '',
            left: props?.left ? props?.left : '',
            right: props?.right ? props?.right : '',
            color: props?.value ? '#1F1F1F' : '#8E8E8E',
          }}
        >
          {props?.symbol}
        </p>
      )}
      <input
        type={props?.type}
        name={props?.name}
        className="input-box input-box-symbol"
        value={props?.value}
        onChange={changes}
        readOnly={props?.readonly}
        id={props?.name}
        autoComplete="false"
        placeholder={props?.placeholder}
        min={props?.min}
        max={props?.max}
        onWheel={(e) => e.target.blur()}
        disabled={props?.disabled}
      />
    </div>
  );
}

function Switch(props) {
  const changes = (e) => {
    props?.change(e.target.name, e.target.checked);
  };
  return (
    <div className="toggle-button">
      {props?.loader ? (
        <label className={props?.checked ? 'switch' : 'disable-switch'} htmlFor={props?.id}>
          <input
            type={props?.type}
            id={props?.id}
            name={props?.name}
            checked={props?.checked}
            onChange={changes}
          />
          <span className="slider round" />
        </label>
      ) : (
        <CircularProgress size={20} color="primary" /> // Ensure size is valid (e.g., 24 for a small loader)
      )}
    </div>
  );
}
function AiSwitch(props) {
  const changes = (e) => {
    props?.change(e.target.name, e.target.checked);
  };
  return (
    <div className="toggle-button">
      <label className="switch" htmlFor={props?.id}>
        <input
          type={props?.type}
          id={props?.id}
          name={props?.name}
          checked={props?.checked}
          onChange={changes}
        />
        <span className="slider round" />
      </label>
    </div>
  );
}

function Switch2(props) {
  return (
    <div className="toggle-button">
      <label className="switch" htmlFor={props?.id}>
        <input
          type={props?.type}
          id={props?.id}
          checked={props?.checked}
          onChange={props?.change}
        />
        <span className="slider round" />
      </label>
    </div>
  );
}

function RadioButton(props) {
  const handleRadioButtonClicked = (event) => {
    props?.onChange(event?.target?.value);
  };

  const handleContainerClick = (key) => {
    if (!props?.disabled) {
      props?.handleOnClick(key);
    }
  };
  return (
    <div
      className="radio-button-wrap"
      role="presentation"
      onClick={() => handleContainerClick(props?.value)}
    >
      <input
        type="radio"
        value={props?.value}
        checked={props?.checked}
        onChange={handleRadioButtonClicked}
        id={props?.value}
        disabled={props?.disabled}
      />
      {props?.allowUnselect
        ? (
          <span
            style={{ '--checkbox-label-color': props?.disabled ? '#9EA3A9' : '#212B36' }}
          >
            {props?.label}
          </span>
        ) : (
          <label
            style={{ '--checkbox-label-color': props?.disabled ? '#9EA3A9' : '#212B36' }}
            htmlFor={props?.value}
          >
            {props?.label}
          </label>
        )}
    </div>
  );
}

function CheckBoxInput(props) {
  const handleCheckBoxClicked = (event) => {
    props?.onChange(event?.target?.value);
  };
  return (
    <div className="radio-button-wrap">
      <input
        type="checkbox"
        value={props?.value}
        checked={props?.checked}
        onChange={handleCheckBoxClicked}
        id={props?.value}
        disabled={props?.disabled}
      />
      <label
        style={{ '--checkbox-label-color': props?.disabled ? '#9EA3A9' : '#212B36' }}
        htmlFor={props?.value}
      >
        {props?.label}
      </label>
    </div>
  );
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  change: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

Input.defaultProps = {
  readonly: false,
  value: '',
  label: '',
  placeholder: '',
  maxLength: Infinity,
  error: false,
  errorMessage: '',
};

InputInnerIcon.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  value: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
};
InputInnerIcon.defaultProps = {
  label: '',
  icon: '',
};

Input1.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  readonly: PropTypes.bool.isRequired,
};
Input1.defaultProps = {
  icon: '',
  label: '',
  placeholder: '',
  error: false,
  errorMessage: '',
};
InputRightInnerIcon.propTypes = {
  change: PropTypes.func.isRequired,
  click: PropTypes.func,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  icon: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};
InputRightInnerIcon.defaultProps = {
  label: '',
  readonly: false,
  placeholder: '',
  icon: '',
  click: () => { },
  error: false,
  errorMessage: '',
};

InputIncreaseDecrease.propTypes = {
  readonly: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  change: PropTypes.func.isRequired,
  index: PropTypes.number,
  value: PropTypes.node,
  required: PropTypes.node.isRequired,
  symbol: PropTypes.node.isRequired,
  top: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  type: PropTypes.node.isRequired,
};
InputIncreaseDecrease.defaultProps = {
  readonly: false,
  top: '',
  left: '',
  right: '',
  placeholder: '',
  min: null,
  max: Infinity,
  index: 0,
  value: '',
  label: '',
};

TextArea.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  change: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};
TextArea.defaultProps = {
  label: '',
  value: '',
  readonly: false,
  placeholder: '',
  error: false,
  errorMessage: '',
};

InputDate.propTypes = {
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  minDate: PropTypes.shape({
    year: PropTypes.number,
    month: PropTypes.number,
    day: PropTypes.number,
  }),
  customPadding: PropTypes.string,
};

InputDate.defaultProps = {
  disabled: false,
  readOnly: false,
  label: '',
  value: '',
  error: false,
  errorMessage: '',
  disablePast: false,
  disableFuture: false,
  minDate: null,
  customPadding: '',
};

InputTime.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disablePast: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  minTime: PropTypes.shape({
    hours: PropTypes.number,
    minutes: PropTypes.number,
  }),
};

InputTime.defaultProps = {
  disabled: false,
  label: '',
  disablePast: false,
  error: false,
  errorMessage: '',
  minTime: null,
};

InputInnerSymbol.propTypes = {
  readonly: PropTypes.bool,
  type: PropTypes.node.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  value: PropTypes.node,
  change: PropTypes.func.isRequired,
  index: PropTypes.number,
  top: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  symbol: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  disabled: PropTypes.bool,
};
InputInnerSymbol.defaultProps = {
  top: '',
  readonly: false,
  left: '',
  right: '',
  value: '',
  index: 0,
  label: '',
  placeholder: '',
  min: null,
  max: Infinity,
  disabled: false,
};

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  loader: PropTypes.bool.isRequired,
};
AiSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};
Switch2.propTypes = {
  type: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};
RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  handleOnClick: PropTypes.func,
  disabled: PropTypes.bool,
  allowUnselect: PropTypes.bool,
};

RadioButton.defaultProps = {
  handleOnClick: () => { },
  disabled: false,
  allowUnselect: false,
};

CheckBoxInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

CheckBoxInput.defaultProps = {
  disabled: false,
};

export {
  Input,
  Input1,
  InputInnerIcon,
  InputRightInnerIcon,
  TextArea,
  InputDate,
  InputTime,
  InputIncreaseDecrease,
  InputInnerSymbol,
  Switch,
  Switch2,
  AiSwitch,
  RadioButton,
  CheckBoxInput,
};
