import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Toggle from './ToggleButton';
import TopContent from '../../../shared/component/TopContent';
import DeleteModal from '../../../shared/component/DeleteModal';

import { fetchSpecialScheduleList, setSpecialScheduleTableFilter } from '../../../redux/slices/ScheduleSlice';
import DropDownMenu from '../../../shared/component/DropDownMenu';

import scheduleApi from '../../../utils/api/schedule';
import { checkFeatures, checkPermission, statusColor } from '../../../utils/helpers';
import DataTable from '../../Table';
import {
  MoreMenuIcon,
  ViewIcon,
  ListMenuIcon,
  CalendarIcon,
  ActiveListMenu,
  ActiveCalendarIcon,
} from '../../../shared/component/svgIcon';
import height from '../../../utils/size-variables';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import ProgressBar from '../../../shared/component/Progressbar';
import ScheduleStatus from './ScheduleStatus';
import ModalBox from '../../../shared/component/ModalBox';

function SpecialSchedule() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const usStateList = [
    { value: 'inProgress', label: 'On-going' },
    { value: 'upcoming', label: 'Upcoming' },
    { value: 'completed', label: 'Completed' },
    { value: 'cancelled', label: 'Cancelled' },
  ];
  const tabPages = [
    {
      key: 'special-schedules',
      icon: <ListMenuIcon />,
      activeIcon: <ActiveListMenu />,
      enable: checkPermission('viewSpecialSchedule'),
    },
    {
      key: 'calendar-schedule',
      icon: <CalendarIcon />,
      activeIcon: <ActiveCalendarIcon />,
      enable: checkPermission('getCalendarSchedule'),
    },
  ];

  const {
    specialSchedules,
    specialPageCount,
    specialTotalPageCount,
    status,
    specialTotalDataCount,
    specialFilter,
    specialScheduleTableFilter,
  } = useSelector((state) => state.schedules);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [scheduleId, setScheduleId] = useState('');
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({});
  const handleChangeTransfer = (id) => {
    setModalOpen(true);
    setModalView({
      title: 'Sync Status',
      content: <ScheduleStatus
        scheduleId={id}
      />,
    });
  };
  const getSchedules = (counts, search) => {
    if (status === 'succeed') {
      dispatch(fetchSpecialScheduleList({ page: counts, ...search }));
    }
  };

  const handleScroll = () => {
    getSchedules(specialPageCount + 1, specialFilter);
  };

  const getDeleteScheduleId = (id) => {
    setScheduleId(id);
    setOpenDeleteModal(true);
  };

  const deleteSchedule = () => {
    scheduleApi.cancelSchedule(scheduleId).then((response) => {
      console.log(response);
      setScheduleId(' ');
      getSchedules(1, specialFilter);
      setOpenDeleteModal(false);
    }).catch((error) => {
      dispatch(setErrorNotification(error?.response?.data));
    });
  };

  const viewSchedule = (id) => {
    navigate(`../schedules/view/${id}`);
  };

  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewSchedule,
      key: 'view',
      id: 'view-schedule',
      disabled: !checkPermission('getSchedule') || !checkFeatures('schedule', 'view_special_schedule'),
    },
    {
      name: t('cancel'),
      url: '',
      function: getDeleteScheduleId,
      key: 'delete',
      id: 'cancel-schedule',
      disabled: !checkPermission('cancelSchedule'),
    },
  ];

  const statusCheck = (name, itemStatus) => name === 'cancel-schedule'
    && ['completed', 'cancelled'].includes(itemStatus);

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('scheduleName'),
      accessorKey: 'name',
      muiFilterTextFieldProps: { placeholder: 'Filter by Schedule Name' },
    },
    {
      header: t('scheduledDate'),
      accessorKey: 'start_date_utc',
      accessorFn: (originalRow) => new Date(originalRow.start_date_utc),
      filterVariant: 'date-range',
      enableColumnFilter: false,
      Cell: ({ row }) => row.original.scheduleDate,
    },
    {
      header: t('scheduledTime'),
      accessorKey: 'scheduledTime',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      filterVariant: 'none',
    },
    {
      header: t('timeZone'),
      accessorKey: 'time_zone',
    },
    {
      header: 'Sync Status',
      enableColumnFilter: false,
      enableSorting: false,
      Header: () => (
        <div className="schedule-status">
          <span>Sync Status</span>
          <Tooltip
            title={(
              <>
                <Box display="flex" alignItems="center" gap={1}>
                  <Box
                    width={12}
                    height={12}
                    bgcolor="#1C75BC"
                    borderRadius="2px"
                  />
                  <span>Device Sync Status</span>
                </Box>
                <Box display="flex" alignItems="center" gap={1} mt={1}>
                  <Box
                    width={12}
                    height={12}
                    bgcolor="#30A84B"
                    borderRadius="2px"
                  />
                  <span>Content Download Status</span>
                </Box>
              </>
            )}
            placement="top"
          >
            <div className="tooltip-container">
              <ViewIcon className="status-view-icon" />
            </div>
          </Tooltip>
        </div>
      ),
      accessorKey: 'timezone',
      size: 250,
      Cell: ({ row }) => (
        <div style={{ width: '100%' }}>
          <ProgressBar
            current={row.original.activeDeviceSyncStatus}
            total={row.original.totalDeviceSyncStatus}
            color="#1C75BC"
            handleClick={() => handleChangeTransfer(row.original.id)}
          />
          <ProgressBar
            current={row.original.downloadedScheduleContentCount}
            total={row.original.totalScheduleContentCount}
            color="#30A84B"
          />
        </div>
      ),
    },
    {
      header: t('status'),
      Header: () => (
        <div className="schedule-status">
          <span>{t('status')}</span>
          <Tooltip title="Status will take up to 1 hour to reflect">
            <div className="tooltip-container">
              <ViewIcon className="status-view-icon" />
            </div>
          </Tooltip>
        </div>
      ),
      accessorKey: 'status',
      filterVariant: 'multi-select',
      filterSelectOptions: usStateList,
      size: 200,
      Cell: ({ cell }) => (
        <Box
          component="span"
          sx={() => ({
            backgroundColor: statusColor(cell.getValue()).backgroundColor,
            borderRadius: '0.25rem',
            color: statusColor(cell.getValue()).color,
            p: '0.25rem',
          })}
        >
          {statusColor(cell.getValue()).statusToShow}
        </Box>
      ),
    },
    {
      header: '',
      accessorKey: 'id',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 70,
      Cell: ({ row }) => (
        <DropDownMenu
          action={actions}
          icon={<MoreMenuIcon />}
          value={row?.original}
          dropdown="tableForm"
          tooltip=""
          statusCheck={statusCheck}
        />
      ),
    },
  ];

  const addSchedule = () => {
    navigate('../schedules/add');
  };

  const calendarNavigate = (url) => {
    navigate(url, { state: { specialSchedule: true } });
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setSpecialScheduleTableFilter(reduxColumnFiltersSorting));
    getSchedules(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    if (specialPageCount === 0 && Object.keys(specialScheduleTableFilter).length === 0) {
      getSchedules(1);
    }
  }, []);

  return (
    <div className="content">
      <div className="main-content">
        <ModalBox
          status={modalOpen}
          closeModal={setModalOpen}
          modalView={modalView}
        />
        <TopContent
          label={t('specialSchedules')}
          buttonClass="success-button"
          button={checkPermission('addSpecialSchedule')}
          buttonLabel={t('newSchedule')}
          click={addSchedule}
        />
        <DeleteModal
          status={openDeleteModal}
          closeModal={setOpenDeleteModal}
          okFunction={deleteSchedule}
          buttonLabel={t('ok')}
          title={t('areYouCancelSchedule')}
          subTitle={t('deleteScheduleSubMessage')}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area special-schedule-table">
              <DataTable
                header={columns}
                value={specialSchedules}
                status={status}
                onFilterOrSortingChange={onFilterOrSortingChange}
                reduxColumnFiltersSorting={specialScheduleTableFilter}
                totalPageCount={specialTotalPageCount}
                pageCount={specialPageCount}
                scrollData={handleScroll}
                totalDataCount={specialTotalDataCount}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                enable={Object.keys(specialFilter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
                isFirstRender={isFirstRender}
                height={height.onlyTableHeight}
                showToolbarLeft={checkFeatures('schedule', 'view_calendar_view')}
                toolbarLeft={<Toggle button1={calendarNavigate} active="special-schedules" tabPages={tabPages} />}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
SpecialSchedule.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.objectOf(),
  }),
  cell: PropTypes.shape({
    getValue: PropTypes.func.isRequired,
  }),
};
SpecialSchedule.defaultProps = {
  cell: null,
  row: {},
};

export default SpecialSchedule;
