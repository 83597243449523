const emailValidator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passwordValidator = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
const urlValidator = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
const youtubeUrlValidator = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(?:-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|live\/|v\/)?)([\w-]+)(\S+)?$/;
const numberValidator = /^[-+]?\d+$/;
const decimalValidator = /^[-+]?(\d+(\.\d*)?|\.\d+)$/;
const globalPincodeValidator = /^[A-Za-z0-9 -]{3,10}$/;
const guidValidator = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[4][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
const phoneNumberValidator = /^\+?[1-9]\d{1,14}$/;

// REGEX for name allowing any language characters
const createNameRegex = (minLength, maxLength) => {
  const regexPattern = `^[\\p{L}\\d][\\p{L}\\p{N}._ @-]{${minLength - 1},${maxLength - 1}}$`;
  return new RegExp(regexPattern, 'u');
};

// REGEX for description allowing any language characters
const createDescriptionRegex = (maxLength = 500) => {
  const regexPattern = `^[\\p{L}\\p{N} .,!?;:'"()\\[\\]{}_/-]{0,${maxLength}}$`;
  return new RegExp(regexPattern, 'u');
};

// REGEX for Text & Numbers allowing any language characters
const createTextNumberRegex = (minLength, maxLength) => {
  const regexPattern = `^[\\p{L}\\p{N}._@ -]{${minLength},${maxLength}}$`;
  return new RegExp(regexPattern, 'u');
};

// REGEX for Text & Numbers allowing any language characters with specified symbols
const createTextNumberWithSymbolsRegex = (minLength, maxLength, allowedSymbols) => {
  // Escape any special characters in the symbols string to avoid regex errors
  const escapedSymbols = allowedSymbols.replace(/[-[\]{}()*+?.^$|\\/]/g, '\\$&');

  const regexPattern = `^[\\p{L}\\d][\\p{L}\\p{N}${escapedSymbols}]{${minLength - 1},${maxLength - 1}}$`;
  return new RegExp(regexPattern, 'u');
};

// Email Validation
export const emailValidation = (value) => emailValidator.test(value);

// Password Validation
export const passwordValidation = (value) => passwordValidator.test(value);

// Password Validation
export const confirmPasswordValidation = (password, confirmPassword) => password !== confirmPassword;

// Phone number Validation
export const phoneNumberValidation = (value) => phoneNumberValidator.test(value);

// URL Validation
export const urlValidation = (value) => {
  if (urlValidator.test(value) && value?.length <= 500) return true;
  return false;
};

// Youtube URL Validation
export const youtubeUrlValidation = (value) => {
  if (youtubeUrlValidator.test(value) && value?.length <= 500) return true;
  return false;
};

// Name Validation
export const nameValidation = (minLength, maxLength, name = '') => {
  const NAME_REGEX = createNameRegex(minLength, maxLength);
  return NAME_REGEX.test(name);
};

// Description Validation
export const descriptionValidation = (maxLength, description = '') => {
  const DESCRIPTION_REGEX = createDescriptionRegex(maxLength);
  return DESCRIPTION_REGEX.test(description);
};

// Text Number Validation
export const textNumberValidation = (minLength, maxLength, value = '') => {
  const TEXT_NUMBER_REGEX = createTextNumberRegex(minLength, maxLength);
  return TEXT_NUMBER_REGEX.test(value);
};

// Text Number Symbol Validation
export const textNumberWithSymbolsValidation = (minLength, maxLength, allowedSymbols = '', value = '') => {
  const TEXT_NUMBER_SYMBOLS_REGEX = createTextNumberWithSymbolsRegex(minLength, maxLength, allowedSymbols);
  return TEXT_NUMBER_SYMBOLS_REGEX.test(value);
};

// Integer Validation
export const integerValidation = (min, max, number = 0) => {
  const value = Number(number);
  return (value <= max && value >= min) && numberValidator.test(value);
};

// Decimal Validation
export const decimalValidation = (min, max, number = 0) => {
  const value = Number(number);
  return (value <= max && value >= min) && decimalValidator.test(value);
};

// Pincode validation
export const pincodeValidation = (value) => globalPincodeValidator.test(value);

// Guuid validation
export const guidValidation = (value) => guidValidator.test(value);

// Tag Validation
export const tagValidation = (maxCharactersInTag, maxTags, tags = []) => {
  // Check if the number of tags exceeds the maximum allowed
  if (tags?.length > maxTags) return false;
  // Check if any tag exceeds the character limit
  const NAME_REGEX = createTextNumberRegex(1, maxCharactersInTag);
  let allTagsAreValid = true;
  tags.forEach((tag) => {
    if (!NAME_REGEX.test(tag)) {
      allTagsAreValid = false;
    }
  });
  return allTagsAreValid; // Return true if no invalid tags are found, false otherwise
};
