/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DeleteIcon, PlusIcon, UploadIcon } from '../../../shared/component/svgIcon';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';
import Button from '../../../shared/component/Button';
import { fetchPreferenceList } from '../../../redux/slices/AccountPreferenceSlice';
import accountPreference from '../../../utils/api/account-preference';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import company from '../../../utils/api/company';
import { Switch2 } from '../../../shared/component/form/Input';
import { getLocalStorageItem } from '../../../utils/helpers';

function AccountPreference() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const data = getLocalStorageItem('data');
  const { preferenceList } = useSelector((state) => state.accountPreference);
  const [selectedImage, setSelectedImage] = useState({
    url: null,
    imageUrl: '',
  });
  const [disable, setDisable] = useState(true);
  const [buttonStatus, setButtonStatus] = useState('');

  const handleImageChange = (e) => {
    const formData = new FormData();
    const files = Array.from(e.target?.files); // Convert FileList to an array
    const maxFileSizeInMB = 2;
    const maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024; // Convert MB to bytes

    if (files?.length > 5) {
      dispatch(setErrorNotification({ message: 'Maximum 5 files should be uploaded at once.' }));
    } else if (files?.some((file) => file.size > maxFileSizeInBytes)) {
      dispatch(setErrorNotification({ message: `File size should be less than ${maxFileSizeInMB} MB` }));
    } else {
      // Loop through each file and append it to formData
      files.forEach((file) => {
        formData.append('player_app_background_images', file);
      });

      if (formData) {
        accountPreference.addPreferenceList(formData)
          .then(() => dispatch(fetchPreferenceList()))
          .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
      }
    }
  };

  const handleSwitch = async () => {
    const updateBody = {
      two_factor: preferenceList.twoFactor ? !preferenceList.twoFactor : true,
    };

    await company.updateCompany(updateBody).then(() => {
      dispatch(fetchPreferenceList());
    }).catch((err) => {
      setErrorNotification(err?.response?.data);
    });
  };

  const handlePasswordSwitch = async () => {
    const updateBody = {
      password_security: preferenceList.passwordSecurity ? !preferenceList.passwordSecurity : true,
    };
    await company.updateCompany(updateBody).then(() => {
      dispatch(fetchPreferenceList());
    }).catch((err) => {
      setErrorNotification(err?.response?.data);
    });
  };
  const submit = () => {
    setButtonStatus('loading');
    accountPreference.updatePreference({ imageUrl: selectedImage.url })
      .then(() => {
        setButtonStatus('success');
        setTimeout(() => {
          dispatch(fetchPreferenceList());
          setButtonStatus(false);
        }, 3000);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
        setButtonStatus(false);
      });
  };

  const handleSelectedImage = (item, url) => {
    setSelectedImage({
      url,
      imageUrl: item,
    });
  };

  const handleDeleteImage = () => {
    accountPreference.deletePreference({ imageUrl: selectedImage?.url })
      .then(() => {
        dispatch(fetchPreferenceList());
        setSelectedImage({});
        setDisable(true);
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
  };

  useEffect(() => {
    if (preferenceList?.results?.length > 0) {
      const backgroundIndex = preferenceList?.results?.findIndex((each) => each.is_active === true);
      setSelectedImage({
        url: backgroundIndex !== -1 ? preferenceList?.results?.[backgroundIndex].url : null,
        imageUrl: backgroundIndex !== -1 ? preferenceList?.results?.[backgroundIndex].signedUrl : null,
      });
    }
  }, [preferenceList]);

  useEffect(() => {
    if (selectedImage?.imageUrl) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [selectedImage]);

  useEffect(() => {
    dispatch(fetchPreferenceList());
  }, []);

  return (
    <div className="content">
      <ModalBox
        status={buttonStatus === 'success'}
        closeModal={setButtonStatus}
        notification
        modalView={{ content: <Success message={t('backgroundImageUploadSuccess')} /> }}
      />
      <div className="preference-wrap">
        <div className="preference-content">
          <h1>{t('backgroundImage')}</h1>
          <p>{t('uploadImageEnjoyContentMessage')}</p>
          {data?.user?._id === data?.user?.company?.admin_user_id
            && (
              <h1>{t('2-factorAuthentication')}</h1>
            )}
          {data?.user?._id === data?.user?.company?.admin_user_id
            && (
              <div className="two-factor">
                <p>{t('turnOn/OffToPreferAuthenticationMethod')}</p>
                <Switch2
                  type="checkbox"
                  name="enable-two-factor"
                  change={() => handleSwitch()}
                  checked={preferenceList?.twoFactor}
                  id={`toggle-checkbox-${preferenceList?.twoFactor}`}
                />
              </div>
            )}
          {data?.user?._id === data?.user?.company?.admin_user_id
            && (
              <h1>{t('passwordSecurity')}</h1>)}
          {data?.user?._id === data?.user?.company?.admin_user_id
            && (
              <div className="two-factor">
                <p>{t('turnOn/OffToPreferPasswordSecurity')}</p>
                <Switch2
                  type="checkbox"
                  name="enable-password-factor"
                  change={() => handlePasswordSwitch()}
                  checked={preferenceList?.passwordSecurity}
                  id={`toggle-passwordbox-${preferenceList?.passwordSecurity}`}
                />
              </div>
            )}
        </div>
        <div className="preference-image-wrap">
          <div className="upload-wrap">
            {!selectedImage?.imageUrl
              ? (
                <div className="upload-container">
                  <label className="upload-text" htmlFor="upload-image">
                    <UploadIcon />
                    <h2>{t('clickOrBrowseToUploadFilesFromYourDevice')}</h2>
                    <p>{t('supportedFormats')}</p>
                  </label>
                  <input
                    id="upload-image"
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={handleImageChange}
                    multiple
                    style={{ display: 'none' }}
                  />
                </div>
              ) : (
                <div className="image-wrap">
                  <div
                    className="delete-container"
                    role="presentation"
                    onClick={handleDeleteImage}
                  >
                    <DeleteIcon />
                  </div>
                  <img
                    src={selectedImage?.imageUrl || ''}
                    alt="account-preference"
                    className="display-image"
                  />
                </div>
              )}
          </div>
          <div className="display-images-wrap">
            {preferenceList?.results?.length > 0
              && (
                <label className="upload-box" htmlFor="upload-image">
                  <PlusIcon />
                </label>
              )}
            <input
              id="upload-image"
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={handleImageChange}
              multiple
              style={{ display: 'none' }}
            />
            {preferenceList?.results?.map((item) => (
              <img
                src={item?.signedUrl}
                alt="uploadedImage"
                className="selected-image"
                role="presentation"
                onClick={() => handleSelectedImage(item?.signedUrl, item?.url)}
              />
            ))}
          </div>
          <div className="form-button-group">
            <div className="form-button">
              <Button
                label={t('setBackgroundImage')}
                disabled={disable}
                click={submit}
                classes={buttonStatus === 'loading' ? 'success-button loading-btn' : 'success-button'}
                loading={buttonStatus === 'loading'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountPreference;
