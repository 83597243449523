import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DataTable from '../../Table';
import ContentView from '../../MyContent/components/ContentView';

import TopContent from '../../../shared/component/TopContent';
import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import ModalBox from '../../../shared/component/ModalBox';
import TableHeaderSelectCount from '../../../shared/component/TableHeaderSelectCount';

import loginImage from '../../../assets/images/loginPage.jpg';
import youtubeIcon from '../../../assets/images/youtube-icon.png';
import urlIcon from '../../../assets/images/url-image.png';
import pdfIcon from '../../../assets/images/pdf-icon.png';

import { addPlaylist } from '../../../redux/slices/PlaylistSlice';
import {
  fetchPlaylistContentList,
  setFilter,
  setTableFilter,
} from '../../../redux/slices/PlaylistContentSlice';
import height from '../../../utils/size-variables';
import { descriptionValidation, nameValidation } from '../../../shared/component/form/Validation';

function PlayListAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const oldValue = useSelector((state) => state.playlist.newPlaylist);
  const {
    playlistContents,
    pageCount,
    totalPageCount,
    totalDataCount,
    status,
    filter,
    tableFilter,
  } = useSelector((state) => state.playlistContent);

  const [disable, setDisable] = useState(true);
  const [value, setValue] = useState({
    contents: {},
    selectedContentDetails: [],
  });
  const [contentModalView, setContentModalView] = useState();
  const [modalView, setModalView] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [contentSelection, setContentSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [allRowsSelected, setAllRowsSelected] = useState(false);

  const getContents = (counts, search) => {
    dispatch(fetchPlaylistContentList({ page: counts, limit: 20, ...search }));
  };

  const backPlaylist = () => {
    navigate('../');
  };

  const submit = () => {
    setSubmitButtonClicked(true);
    if (nameValidation(1, 50, value?.name)
      && descriptionValidation(500, value?.description)
    ) {
      dispatch(addPlaylist(value));
      navigate('../add-view', { state: { page: 'add' } });
    }
  };

  const selectData = (name, data) => {
    setValue({ ...value, [name]: data });
  };

  const viewContent = (singleContent) => {
    setModalView({
      title: 'Preview',
      content: <ContentView singleContent={singleContent} />,
    });
    setContentModalView(true);
  };

  const handleRowSelection = (selectedRows) => {
    setContentSelection(selectedRows);
    if (Object.keys(selectedRows).length === playlistContents.length) {
      setAllRowsSelected(true);
    } else {
      setAllRowsSelected(false);
    }
  };

  const handleScroll = () => {
    getContents(pageCount + 1, filter);
  };

  useEffect(() => {
    if (Object.keys(contentSelection).length >= 20 || allRowsSelected) {
      const newContentSelection = { ...contentSelection };
      playlistContents.forEach((content) => {
        if (!newContentSelection[content?.id]) {
          newContentSelection[content?.id] = true;
        }
      });
      setContentSelection(newContentSelection);
    }
  }, [playlistContents]);

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('contentName'),
      accessorKey: 'name',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => (
        <div className="content-thumbnail">
          {
            (() => {
              let thumbnailImage;
              if (row.original?.file_type === 'youtube') {
                thumbnailImage = (
                  <img
                    alt="Youtube"
                    src={youtubeIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else if (row.original?.file_type === 'url') {
                thumbnailImage = (
                  <img
                    alt="Url"
                    src={urlIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else if (row.original?.file_type?.includes('pdf')) {
                thumbnailImage = (
                  <img
                    alt="Url"
                    src={pdfIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else {
                thumbnailImage = (
                  <img
                    alt="thumbnail"
                    src={
                      row.original?.thumbnailSignedUrls?.px_50x50
                      || loginImage
                    }
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                  />
                );
              }
              return thumbnailImage;
            })()
          }
          <span className="content_name">
            {row.original?.name}
          </span>
        </div>
      ),
    },
    {
      header: t('mediaType'),
      accessorKey: 'file_type',
      enableSorting: false,
      Cell: ({ row }) => row.original?.file_type_to_show,
    },
    {
      header: t('managedBy'),
      accessorKey: 'author',
      Cell: ({ row }) => row.original?.author,
    },
    {
      header: t('updatedBy'),
      accessorKey: 'updatedAt',
      accessorFn: (originalRow) => new Date(originalRow?.updatedAt),
      filterVariant: 'date-range',
      enableColumnFilter: false,
      Cell: ({ row }) => row.original?.updateDate,
    },
    {
      header: t('fileSize'),
      accessorKey: 'file_size',
      enableColumnFilter: false,
    },
  ];

  useEffect(() => {
    const selectedContents = playlistContents.filter((content) => Object.keys(contentSelection).includes(content.id));
    setValue({
      ...value,
      contents: contentSelection,
      selectedContentDetails: selectedContents,
    });
  }, [contentSelection]);

  useEffect(() => {
    if (value?.name && Object.keys(contentSelection)?.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilter(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getContents(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilter).length === 0) {
      getContents(1);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(oldValue)?.length !== 0 && location?.state?.page === 'add-view') {
      setValue(oldValue);
      setContentSelection(oldValue?.contents);
      dispatch(addPlaylist({}));
    }
  }, []);

  return (
    <div className="content content-wrap">
      <ModalBox
        status={contentModalView}
        closeModal={setContentModalView}
        modalView={modalView}
        notification={false}
      />
      <div className="main-content">
        <TopContent
          label={[t('playlist'), t('createNewPlaylist')]}
          buttonClass="success-button"
          button={false}
          buttonLabel="Back"
          click={backPlaylist}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="my-content-form">
                <Input
                  icon={' '}
                  label={t('playlistName')}
                  type="text"
                  name="name"
                  required
                  value={value?.name}
                  placeholder={t('enterPlaylistName')}
                  change={selectData}
                  error={!nameValidation(1, 50, value?.name) && submitButtonClicked}
                  errorMessage={t('playlistNameValidation')}
                />
                <Input
                  icon={' '}
                  label={t('description')}
                  type="text"
                  name="description"
                  required={false}
                  value={value?.description}
                  placeholder={t('enterDescription')}
                  change={selectData}
                  error={!descriptionValidation(500, value?.description) && submitButtonClicked}
                  errorMessage={t('descriptionValidation')}
                />
              </div>
              <DataTable
                header={columns}
                value={[
                  ...value.selectedContentDetails,
                  ...playlistContents.filter((content) => !Object.keys(value.contents).includes(content.id)),
                ]}
                status={status}
                reduxColumnFiltersSorting={tableFilter}
                onFilterOrSortingChange={onFilterOrSortingChange}
                totalPageCount={totalPageCount}
                pageCount={pageCount}
                scrollData={handleScroll}
                totalDataCount={totalPageCount}
                rowSelection={contentSelection}
                setRowSelection={handleRowSelection}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                enable={tableFilter?.columnFilters?.length > 0}
                height={height.formTableHeight}
                isFirstRender={isFirstRender}
                disableFullScreenToggle
                enableRowSelection
                toolbarLeft={(
                  <TableHeaderSelectCount
                    select={Object.keys(contentSelection).length}
                    total={totalDataCount}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="form-button-group">
          <div className="form-button">
            <Button label={t('back')} click={backPlaylist} classes="default-button" />
          </div>
          <div className="form-button">
            <Button label={t('continue')} click={submit} classes="success-button" disabled={disable} />
          </div>
        </div>
      </div>
    </div>
  );
}
PlayListAdd.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      name: PropTypes.string,
      file_type: PropTypes.string.isRequired,
      thumbnailSignedUrls: PropTypes.shape({
        px_50x50: PropTypes.string.isRequired,
      }),
      author: PropTypes.string,
      updatedAt: PropTypes.string,
      updateDate: PropTypes.string,
      file_size: PropTypes.string,
    }),
  }),
};

PlayListAdd.defaultProps = {
  row: {
    index: 0,
    original: {},
  },
};

export default PlayListAdd;
