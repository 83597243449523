import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TopContent from '../../shared/component/TopContent';

import Toggle from './components/ToggleButton';
import DeleteModal from '../../shared/component/DeleteModal';

import {
  addNewSchedule,
  deleteSchedule,
  fetchScheduleList,
  setFilter,
  setTableFilter,
} from '../../redux/slices/ScheduleSlice';
import DropDownMenu from '../../shared/component/DropDownMenu';

import scheduleApi from '../../utils/api/schedule';
import { checkFeatures, checkPermission, statusColor } from '../../utils/helpers';
import DataTable from '../Table';
import exportCSV from '../Global/exportCSV';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import {
  ListMenuIcon,
  CalendarIcon,
  ActiveListMenu,
  ActiveCalendarIcon,
  MoreMenuIcon,
  ViewIcon,
} from '../../shared/component/svgIcon';
import height from '../../utils/size-variables';
import ProgressBar from '../../shared/component/Progressbar';
import ModalBox from '../../shared/component/ModalBox';
import ScheduleStatus from './components/ScheduleStatus';

function Schedule() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const calendarNavigate = (url) => {
    navigate(url, { state: { specialSchedule: false } });
  };
  const usStateList = [
    { value: 'inProgress', label: 'On-going' },
    { value: 'upcoming', label: 'Upcoming' },
    { value: 'completed', label: 'Completed' },
    { value: 'cancelled', label: 'Cancelled' },
  ];
  const tabPages = [
    {
      key: 'schedules',
      icon: <ListMenuIcon />,
      activeIcon: <ActiveListMenu />,
      enable: checkPermission('getSchedule'),
    },
    {
      key: 'calendar-schedule',
      icon: <CalendarIcon />,
      activeIcon: <ActiveCalendarIcon />,
      enable: checkPermission('getCalendarSchedule'),
    },
  ];

  const {
    schedules,
    pageCount,
    totalPageCount,
    status,
    totalDataCount,
    filter,
    tableFilter,
  } = useSelector((state) => state.schedules);

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [singleSchedule, setSingleSchedule] = useState('');

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const getSchedules = (counts, search) => {
    if (status === 'succeed') {
      dispatch(fetchScheduleList({ page: counts, ...search }));
    }
  };

  const handleScroll = () => {
    getSchedules(pageCount + 1, filter);
  };

  const getDeleteScheduleId = (id) => {
    setSingleSchedule(id);
    setOpenDeleteModal(true);
  };

  const deleteScheduleById = () => {
    scheduleApi.cancelSchedule(singleSchedule.id)
      .then(() => {
        dispatch(deleteSchedule(singleSchedule));
        setSingleSchedule(' ');
        setOpenDeleteModal(false);
      }).catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({});
  const handleChangeTransfer = (id) => {
    setModalOpen(true);
    setModalView({
      title: 'Sync Status',
      content: <ScheduleStatus
        scheduleId={id}
      />,
    });
  };
  const viewSchedule = (id) => {
    navigate(`view/${id}`);
  };

  const cloneSchedule = (id) => {
    navigate(`edit/${id}?type=clone`);
  };

  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewSchedule,
      key: 'view',
      disabled: !checkPermission('getSchedule') || !checkFeatures('schedule', 'view'),
    },
    {
      name: t('clone'),
      url: '',
      function: cloneSchedule,
      key: 'view',
      disabled: !checkPermission('cloneSchedule') || !checkFeatures('schedule', 'clone_schedule'),
    },
    {
      name: t('cancel'),
      id: 'cancel-schedule',
      url: '',
      function: getDeleteScheduleId,
      key: 'delete',
      disabled: !checkPermission('cancelSchedule') || !checkFeatures('schedule', 'delete'),
      sendFullDetails: true,
    },
  ];

  const statusCheck = (name, itemStatus) => name === 'cancel-schedule'
    && ['completed', 'cancelled'].includes(itemStatus);

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('scheduleName'),
      accessorKey: 'name',
      muiFilterTextFieldProps: { placeholder: 'Filter by Schedule Name' },
    },
    {
      header: t('scheduledDate'),
      accessorKey: 'start_date_utc',
      accessorFn: (originalRow) => new Date(originalRow.start_date_utc),
      filterVariant: 'date-range',
      enableColumnFilter: false,
      Cell: ({ row }) => row.original.scheduleDate,
    },
    {
      header: t('scheduledTime'),
      accessorKey: 'scheduledTime',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      filterVariant: 'none',
    },
    {
      header: t('timeZone'),
      accessorKey: 'time_zone',
    },
    {
      header: 'Sync Status',
      enableColumnFilter: false,
      enableSorting: false,
      Header: () => (
        <div className="schedule-status">
          <span>Sync Status</span>
          <Tooltip
            title={(
              <>
                <Box display="flex" alignItems="center" gap={1}>
                  <Box
                    width={12}
                    height={12}
                    bgcolor="#1C75BC"
                    borderRadius="2px"
                  />
                  <span>Device Sync Status</span>
                </Box>
                <Box display="flex" alignItems="center" gap={1} mt={1}>
                  <Box
                    width={12}
                    height={12}
                    bgcolor="#30A84B"
                    borderRadius="2px"
                  />
                  <span>Content Download Status</span>
                </Box>
              </>
            )}
            placement="top"
          >
            <div className="tooltip-container">
              <ViewIcon className="status-view-icon" />
            </div>
          </Tooltip>
        </div>
      ),
      accessorKey: 'timezone',
      size: 250,
      Cell: ({ row }) => (
        <div style={{ width: '100%' }}>
          <ProgressBar
            current={row.original.activeDeviceSyncStatus}
            total={row.original.totalDeviceSyncStatus}
            color="#1C75BC"
            handleClick={() => handleChangeTransfer(row.original.id)}
          />
          <ProgressBar
            current={row.original.downloadedScheduleContentCount}
            total={row.original.totalScheduleContentCount}
            color="#30A84B"
          />
        </div>
      ),
    },
    {
      header: t('status'),
      Header: () => (
        <div className="schedule-status">
          <span>{t('status')}</span>
          <Tooltip title="Status will take up to 1 hour to reflect">
            <div className="tooltip-container">
              <ViewIcon className="status-view-icon" />
            </div>
          </Tooltip>
        </div>
      ),
      accessorKey: 'status',
      filterVariant: 'multi-select',
      filterSelectOptions: usStateList,
      size: 200,
      Cell: ({ cell }) => (
        <Box
          component="span"
          sx={() => ({
            backgroundColor: statusColor(cell.getValue()).backgroundColor,
            borderRadius: '0.25rem',
            color: statusColor(cell.getValue()).color,
            p: '0.25rem',
          })}
        >
          {statusColor(cell.getValue()).statusToShow}
        </Box>
      ),
    },
    {
      header: '',
      accessorKey: 'id',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 70,
      Cell: ({ row }) => (
        <DropDownMenu
          action={actions}
          icon={<MoreMenuIcon />}
          value={row?.original}
          dropdown="tableForm"
          tooltip=""
          statusCheck={statusCheck}
        />
      ),
    },
  ];

  const addSchedule = () => {
    navigate('add');
  };

  const exportData = () => {
    const columnHeaders = ['name', 'start_only_date', 'end_only_date', 'start_time', 'end_time', 'time_zone', 'status'];

    // Filter the hidden column
    const filteredHeaders = columnHeaders.filter((header) => {
      if (header === 'start_only_date' || header === 'end_only_date') {
        return columnVisibility.start_date_utc !== false;
      }
      if (header === 'start_time' || header === 'end_time') {
        return columnVisibility.scheduledTime !== false;
      }
      return columnVisibility[header] !== false;
    });
    // API call for export CSV
    scheduleApi.scheduleDataExport({ fields: filteredHeaders, ...filter })
      .then((res) => {
        // Function to export as CSV file
        const csvData = res?.data?.map((item) => ({
          name: item?.name || '',
          time_zone: item?.time_zone || '',
          data: item?.start_only_date ? `${item?.start_only_date} to ${item?.end_only_date}` : '',
          time: item?.start_time ? `${item?.start_time} to ${item?.end_time}` : '',
          status: item?.status,
        }));
        exportCSV(Object.keys(csvData?.[0]), csvData);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilter(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getSchedules(1, filteredValues);
  };

  useEffect(() => {
    dispatch(addNewSchedule({
      schedule: {
        playlists: [],
        playlistDetails: [],
        contents: [],
        contentsDetails: [],
        devices: [],
        device_groups: [],
        repeat_days: [],
        repeat: false,
        special: false,
      },
      dateTime: {},
    }));
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilter).length === 0) {
      getSchedules(1);
    }
  }, []);

  return (
    <div className="content">
      <div className="main-content">
        <ModalBox
          status={modalOpen}
          closeModal={setModalOpen}
          modalView={modalView}
        />
        <TopContent
          label={t('schedule')}
          buttonClass="success-button schedule-button"
          button={checkPermission('addSchedule') && checkFeatures('schedule', 'create')}
          buttonLabel={t('newSchedule')}
          click={addSchedule}
        />
        <DeleteModal
          status={openDeleteModal}
          closeModal={setOpenDeleteModal}
          okFunction={deleteScheduleById}
          buttonLabel={t('ok')}
          title={t('areYouSureYouWantToCancelThisSchedule?')}
          subTitle=<span> {t('thisWillCancelTheSchedulePermanently')}<br />{t('youCannotUndoThisAction.')}</span>
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area schedule-table">
              <DataTable
                header={columns}
                value={schedules}
                status={status}
                onFilterOrSortingChange={onFilterOrSortingChange}
                reduxColumnFiltersSorting={tableFilter}
                totalPageCount={totalPageCount}
                pageCount={pageCount}
                scrollData={handleScroll}
                totalDataCount={totalDataCount}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
                height={height.onlyTableHeight}
                exportButton={checkPermission('exportSchedule')}
                exportData={exportData}
                isFirstRender={isFirstRender}
                showToolbarLeft={checkFeatures('schedule', 'view_calendar_view')}
                toolbarLeft={<Toggle button1={calendarNavigate} active="schedules" tabPages={tabPages} />}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
Schedule.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.objectOf(),
  }),
  cell: PropTypes.shape({
    getValue: PropTypes.func.isRequired,
  }),
};
Schedule.defaultProps = {
  cell: null,
  row: {},
};
export default Schedule;
